import { Route } from '@angular/router';
import { SoftServicesComponent } from '@bidvest/features';
import { canActivate } from '@bidvest/shared';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () => import('@bidvest/auth').then((m) => m.authRoutes)
  },
  { path: 'soft-services', component: SoftServicesComponent },
  {
    path: '',
    canActivate: [canActivate],
    loadChildren: () => import('@bidvest/layout').then((m) => m.dashboardRoutes)
  }
];

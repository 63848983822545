import { Route } from '@angular/router';
import { canActivate } from '@kay-mason-foundation/shared';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () => import('@bidvest/auth').then((m) => m.authRoutes)
  },
  {
    path: '',
    canActivate: [canActivate],
    loadChildren: () => import('@bidvest/layout').then((m) => m.dashboardRoutes)
  }
];

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NxWelcomeComponent } from './nx-welcome.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  ErrorInterceptor,
  TokenService,
  AuthInterceptor
} from '@kay-mason-foundation/shared';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { provideToastr } from 'ngx-toastr';

export function tokenGetter() {
  return TokenService.TOKEN;
}
@NgModule({
  declarations: [AppComponent, NxWelcomeComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: { tokenGetter: tokenGetter, skipWhenExpired: true }
    })
  ],
  providers: [
    provideAnimationsAsync(),
    provideToastr(),
    provideHttpClient(withInterceptorsFromDi()),
    ErrorInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
